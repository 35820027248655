import { datadogRum } from '@datadog/browser-rum';

window.init_datadog = init_datadog;

function init_datadog(idwEnv) {
  if ( idwEnv != 'development' && idwEnv != 'test' ) {
    datadogRum.init({
      applicationId: '5b45b6fd-ca92-4123-be66-f274209ae787',
      clientToken: 'pube5421a1bb492faff20f3632c06074746',
      site: 'datadoghq.com',
      service: 'idw-apptier-dashboard',
      env: idwEnv,
      sessionSampleRate: 100,
      sessionReplaySampleRate: 20,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'mask-user-input',
      allowedTracingUrls: [ (url) => url.startsWith("http://localhost"), 
        (url) => url.startsWith("https://ms.idwatchdog.com"), 
        (url) => url.startsWith("https://ms-idw"),
        (url) => url.startsWith("https://beta-ms-idwatchdog") ]
    });
  }
};